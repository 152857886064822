import React, {useState} from 'react'
import { PageTopBar } from '../../common/components/Page/PageTopBar'
import { PageHeadline } from '../../common/components/Page/PageHeadline'
import LineSpacer from '../../common/components/Spacer/LineSpacer'
import {Input} from "../../common/components/Form/Input";
import {Button} from "../../common/components/Button/Button";

interface ISolebox {
}

const Solebox: React.FunctionComponent<ISolebox> = props => {
    const [atcPid, setAtcPid] = useState<string>('')

    return (
    <>
      <PageTopBar
        headline={<PageHeadline>Solebox</PageHeadline>}
        actions={[]}
      />
        <p className={'text-left'}>This will help you to add not loaded Solebox products to your account cart (pre cart). Solebox does not have a Wishlist Feature<br/>Please follow the following steps</p>
        <ul className={'text-left'}>
            <li>- Open the Solebox website in a separate tab and login to your account</li>
            <li>- Grab the correct Pid for your product out of the Solem8s discord group</li>
            <li>- Submit the form and you will be redirected to a page with some text</li>
            <li>- Close that page and go to the Solebox page</li>
        </ul>
        <br/>01900287
        <p className={'text-red-700'}>The Solebox PID for a Size usually consist out of 16 digits. The PID for the
            product itself are the first 8 digits. The last 8 are for the size.</p>
        <LineSpacer/>
        <h3>Solebox ATC</h3>
        <form target={'_blank'}
              method={'POST'}
              onSubmit={(e) => {
                  setTimeout(function () {
                      window.open('https://www.solebox.com/de_DE/cart', '_blank')
                  }, 3000)
              }}
              action={'https://www.solebox.com/de_DE/add-product?format=ajax'}>
            <Input name={'quantity'} displayLabel={false} type={'hidden'} value={1}/>
            <Input name={'options'} displayLabel={false} type={'hidden'} value={''}/>
            <Input name={'pid'}
                   placeholder={'enter pid here...'}
                   label={'Product PID (Size):'}
                   onChange={(e) => {
                       setAtcPid(e.target.value)
                   }}/>
            <Button label={'Add to cart'} onClick={() => console.log('atc pid', atcPid)}/>
        </form>
    </>
  )

}

export default Solebox
