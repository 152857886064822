import React from 'react'

interface IPageTopBar {
  headline: JSX.Element
  description?: JSX.Element
  actions: JSX.Element[]
}

export const PageTopBar: React.FunctionComponent<IPageTopBar> = props => {
  const { headline, description, actions } = props

  return (
    <>
      <div className='flex items-center justify-between dark:text-white'>
        {headline}
        <div className='flex'>
          {actions.map((action, index) => {
            return (
              <div
                key={index}
                className='ml-2'
              >
                {action}
              </div>
            )
          })}
        </div>
      </div>
      {description}
      <br/>
    </>
  )
}
