import React, {ReactNode} from 'react'

interface IButton extends React.DetailedHTMLProps<React.LinkHTMLAttributes<HTMLLinkElement>, HTMLLinkElement> {
  label?: ReactNode
  onClick: (e: any) => void
}

export const Button: React.FunctionComponent<IButton> = props => {
  const {label, onClick} = props

  return (
    <button className="bg-red-sb hover:bg-red-sb text-white font-bold py-2 px-4 rounded-sm my-3" onClick={(e) => onClick(e)}>
      {label}
    </button>
  )
}
