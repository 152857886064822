import React from 'react'
import { ShoppingCart, Grid } from 'react-feather'
import { NavigationItem } from './NavigationItem'


interface INavigation {
}

export const Navigation: React.FunctionComponent<INavigation> = () => {

  return (
    <div className={'h-screen bg-white w-32 uppercase font-medium fixed top-0 left-0 dark:bg-grey-solemate'}>
      <ul className={'list-none h-screen mt-16 shadow-md'}>
        <NavigationItem link={'/dashboard'} icon={<Grid style={{ margin: 'auto' }}/>} name={'Dashboard'}/>
        <NavigationItem link={'/solebox'} icon={<ShoppingCart style={{ margin: 'auto' }}/>} name={'Solebox'}/>
        <NavigationItem link={'/snipes'} icon={<ShoppingCart style={{ margin: 'auto' }}/>} name={'Snipes'}/>
        <NavigationItem link={'/onygo'} icon={<ShoppingCart style={{ margin: 'auto' }}/>} name={'Onygo'}/>
      </ul>
    </div>
  )
}
