import React from 'react'

interface IDropdown {
  display: boolean
}

const Dropdown: React.FunctionComponent<IDropdown> = props => {
  const { display, children } = props

  if (!display) {
    return null
  }

  return (
    <div style={{ top: 50 }} className="origin-top-right absolute right-0 mt-2 w-32 rounded-md shadow-lg">
      <div className="cursor-pointer rounded-md bg-white dark:bg-grey-solemate shadow-xs">
        <div className="py-1">
          {children}
        </div>
      </div>
    </div>
  )
}

export default Dropdown
