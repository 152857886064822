import React from 'react'

interface ILineSpacer {

}

const LineSpacer: React.FunctionComponent<ILineSpacer> = props => {
  return (
    <div className="border-red-sb mt-8 mb-6 border-2 mr-0 -ml-2"/>
  )
}

export default LineSpacer
