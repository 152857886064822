import React, { ReactNode } from 'react'

interface IInput extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label?: ReactNode
  name: string
  displayLabel?: boolean
}

export const Input: React.FunctionComponent<IInput> = props => {
  const { label, name, displayLabel = true, id, ...rest } = props
  const hasLabel = label !== undefined

  return (
    <div className="">
      {(hasLabel || name) && displayLabel &&
      <label className="block text-grey-700 dark:text-white text-m font-semibold text-left" htmlFor={id}>
        {hasLabel ? label : name}
      </label>
      }
      <input
        id={id}
        name={name}
        className="shadow border-grey-400 appearance-none border-2 rounded-sm w-full py-2 px-2 text-grey-700 leading-tight focus:outline-none focus:shadow-outline dark:border-grey-800 dark:bg-grey-solemateLight dark:text-white"
        {...rest}
      />
    </div>
  )
}
