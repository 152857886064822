import React from 'react'

interface IPageHeadline {
  isInline?: boolean
  children: string
}

export const PageHeadline: React.FunctionComponent<IPageHeadline> = props => {
  const { children, isInline = false } = props

  const classes = ['text-3xl']

  if (isInline) {
    classes.push('inline-block')
  }

  const classString = classes.join(' ')

  return (
    <h1 className={classString}>{children}</h1>
  )
}
