import React from 'react'
import { PageHeadline } from '../../common/components/Page/PageHeadline'
import { PageTopBar } from '../../common/components/Page/PageTopBar'
import logo from '../../common/assets/solem8sguywithlogo.png'

const Dashboard: React.FunctionComponent = props => {
  return (
    <>
      <PageTopBar
        headline={<PageHeadline>Dashboard</PageHeadline>}
        actions={[]}
      />
      <div>
        <img className={'text-center'} alt={'solem8s'} style={{ margin: '0px auto' }} src={logo} width={'50%'}/>
      </div>
      <p>Thank you for joining Solem8s</p>
      <p className={'text-sm'}>You are here and not part of Solem8s? Consider joining us <a className={'text-red-700'} href={'https://solem8s.de/newsletter'}>here</a></p>
    </>
  )
}

export default Dashboard
