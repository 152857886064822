import React from 'react'

interface IDropdownItem {
}

const DropdownItem: React.FunctionComponent<IDropdownItem> = props => {
  const { children } = props

  return (
    <div className="py-1">
      <span className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none dark-hover:border-grey-solemateLight dark:text-white">
        {children}
      </span>
    </div>
  )
}

export default DropdownItem
