import Dropdown from '../../common/components/Dropdown/Dropdown'
import DropdownItem from '../../common/components/Dropdown/DropdownItem'
import {Moon, Sun} from 'react-feather'
import {Navigation} from '../../common/components/Navigation/Navigation'
import {Route, Switch} from 'react-router-dom'
import Dashboard from '../Dashboard/Dashboard'
import logo from './../../common/assets/solem8slogo.png'
import React, {useState} from 'react'
import Snipes from '../Snipes/Snipes'
import Solebox from '../Solebox/Solebox'
import Onygo from '../Onygo/Onygo'

interface IMain {
    darkMode: boolean
    onSetDarkMode: (mode: boolean) => void
}

const Main: React.FunctionComponent<IMain> = props => {
    const {darkMode, onSetDarkMode} = props

    const [userSettingsOpen, setUserSettingsOpen] = useState(false)

    return (
        <>
            <div>
                <div className='bg-white h-16 w-full fixed top-0 left-0 shadow-md z-50 px-2 dark:bg-grey-solemate'>
                    <div className='h-16 flex justify-between items-center dark:text-white'>
                        <div>
                            <img alt={'solem8s-logo'} width={124} className='h-8' src={logo}/>
                        </div>
                        <div className='flex items-center'>
                            <div
                                onClick={() => setUserSettingsOpen(!userSettingsOpen)}
                                style={{backgroundImage: `url()`, backgroundSize: '100%'}}
                                className="rounded-full h-10 w-10 ml-2 flex items-center justify-center cursor-pointer">
                            </div>
                            <Dropdown display={userSettingsOpen}>
                                <DropdownItem>
                                    {darkMode ?
                                        <span onClick={() => onSetDarkMode(false)}>
                      <div className='flex justify-between items-center'><Sun/> Lightmode</div>
                    </span> :
                                        <span onClick={() => onSetDarkMode(true)}>
                      <div className='flex justify-between items-center'><Moon/> Darkmode</div>
                    </span>
                                    }
                                </DropdownItem>
                            </Dropdown>
                        </div>
                    </div>
                </div>
                <Navigation/>
                <div style={{width: 'calc(100% - 8rem)'}} className='float-left p-12 ml-32 mt-16'>
                    <Switch>
                        <Route path="/dashboard" component={Dashboard}/>
                        <Route path="/solebox" component={Solebox}/>
                        <Route path="/snipes" component={Snipes}/>
                        <Route path="/onygo" component={Onygo}/>
                        <Route component={Dashboard}/>
                    </Switch>
                </div>
            </div>
        </>
    )
}

export default Main
