import React, { useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import './App.css';
import Main from './features/Main/Main'

export const AppEntry = (props: any) => {
  const [darkMode, setDarkMode] = useState(false)

  useEffect(() => {
    const html = document.getElementById('htmlTag')

    if (!html) {
      return
    }

    const name = 'mode-dark'
    if (darkMode) {
      html.className += ' ' + name
    } else {
      html.className = html.className.replace(name, '')
    }
  }, [darkMode])

  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        limit={3}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Main
        darkMode={darkMode}
        onSetDarkMode={setDarkMode}
      />
    </div>
  )
}
