import React, {useState} from 'react'
import {PageTopBar} from '../../common/components/Page/PageTopBar'
import {PageHeadline} from '../../common/components/Page/PageHeadline'
import LineSpacer from '../../common/components/Spacer/LineSpacer'
import {Input} from "../../common/components/Form/Input";
import {Button} from "../../common/components/Button/Button";

interface ISnipes {
}

const Onygo: React.FunctionComponent<ISnipes> = props => {
    const [wishlistPid, setWishlistPid] = useState<string>('')
    const [atcPid, setAtcPid] = useState<string>('')

    return (
        <>
            <PageTopBar
                headline={<PageHeadline>Onygo</PageHeadline>}
                actions={[]}
            />
            <p className={'text-left'}>This will help you to add not loaded onygo products to your account wishlist or
                directly to your cart (pre cart).<br/>Please follow the following steps</p>
            <ul className={'text-left'}>
                <li>- Open the onygo website in a separate tab and login to your account</li>
                <li>- Grab the correct Pid for your product out of the Solem8s discord group</li>
                <li>- Submit the form and you will be redirected to a page with some text</li>
                <li>- Close that page and go to the Onygo page</li>
            </ul>
            <br/>
            <p className={'text-red-700'}>The Onygo PID for a Size usually consist out of 22 digits. The PID for the
                product itself are the first 14 digits. The last 8 are for the size.</p>
            <LineSpacer/>
            <h2>Onygo Wishlist Add</h2>
            <form target={'_blank'}
                  method={'POST'}
                  onSubmit={(e) => {
                      setTimeout(function () {
                          window.open('https://www.onygo.com/wishlist', '_blank')
                      }, 3000)
                  }}
                  action={'https://www.onygo.com/on/demandware.store/Sites-ong-DE-Site/de_DE/Wishlist-AddProduct?format=ajax'}>
                <Input name={'pid'}
                       placeholder={'enter pid here...'}
                       label={'Product PID:'}
                       onChange={(e) => {
                           setWishlistPid(e.target.value)
                       }}/>
                <Button label={'Add to wishlist'} onClick={() => console.log('wishlist pid', wishlistPid)}/>
            </form>
            <LineSpacer/>
            <h3>Onygo ATC</h3>
            <form target={'_blank'}
                  method={'POST'}
                  onSubmit={(e) => {
                      setTimeout(function () {
                          window.open('https://www.onygo.com/cart', '_blank')
                      }, 3000)
                  }}
                  action={'https://www.onygo.com/add-product?format=ajax'}>
                <Input name={'quantity'} displayLabel={false} type={'hidden'} value={1}/>
                <Input name={'options'} displayLabel={false} type={'hidden'} value={''}/>
                <Input name={'pid'}
                       placeholder={'enter pid here...'}
                       label={'Product PID (Size):'}
                       onChange={(e) => {
                           setAtcPid(e.target.value)
                       }}/>
                <Button label={'Add to cart'} onClick={() => console.log('atc pid', atcPid)}/>
            </form>

        </>
    )

}

export default Onygo
