import React, {useEffect, useState} from 'react'
import Select from 'react-select'
import {PageTopBar} from '../../common/components/Page/PageTopBar'
import {PageHeadline} from '../../common/components/Page/PageHeadline'
import LineSpacer from '../../common/components/Spacer/LineSpacer'
import {Input} from '../../common/components/Form/Input'
import {Button} from '../../common/components/Button/Button'

interface ISnipes {
}

interface ISelectedRegion {
    value: string
    label: string
}

const regions = [
    {value: 'com', label: 'Germany'},
    {value: 'at', label: 'Austria'},
    {value: 'ch', label: 'Switzerland'},
]

const regionInformationForAtc: { [key: string]: string } = {
    'com': 'Sites-snse-DE-AT-Site/de_DE|add-product?format=ajax',
    'at': 'Sites-snse-DE-AT-Site/de_AT|add-product?format=ajax',
    'ch': 'Sites-snse-CH-Site/de_CH|on/demandware.store/Sites-snse-CH-Site/de_CH/Cart-AddProduct?format=ajax',
}

const Snipes: React.FunctionComponent<ISnipes> = props => {
    const defaultRegion = {
        value: 'com',
        label: 'Germany'
    }
    const [wishlistPid, setWishlistPid] = useState<string>('')
    const [cookieSuccessful, setCookieSuccessful] = useState<boolean>(false)
    const [atcPid, setAtcPid] = useState<string>('')
    const [selectedRegion, setSelectedRegion] = useState<ISelectedRegion>(defaultRegion)

    useEffect(() => {
        const cookieValue = getCookie("solem8s_snipes")

        if (!cookieValue) {
            return
        }

        const foundRegion = regions.find((r) => r.value === cookieValue)

        if (foundRegion) {
            setSelectedRegion(foundRegion)
        }
    }, [])

    const getSelectedRegionInformation = (): string[] => {
        const cookieValue = getCookie("solem8s_snipes")

        let region = defaultRegion
        const foundRegion = regions.find((r) => r.value === cookieValue)

        if (foundRegion) {
            region = foundRegion
        }

        const information = regionInformationForAtc[region.value]
        return information.split('|')
    }

    return (
        <>
            <PageTopBar
                headline={<PageHeadline>Snipes</PageHeadline>}
                actions={[]}
            />
            <p className={'text-left'}>This will help you to add not loaded snipes products to your account wishlist or
                directly to your cart (pre cart).<br/>Please follow the following steps</p>
            <ul className={'text-left'}>
                <li>- Open the snipes website in a separate tab and login to your account</li>
                <li>- Grab the correct Pid for your product out of the Solem8s discord group</li>
                <li>- Submit the form and you will be redirected to a page with some text</li>
                <li>- Close that page and go to the Snipes page</li>
            </ul>
            <br/>
            <p className={'text-red-700'}>The Snipes PID for a Size usually consist out of 22 digits. The PID for the
                product itself are the first 14 digits. The last 8 are for the size.</p>
            <LineSpacer/>
            <h2>Region Selector</h2>
            <p>Select your Snipes Region. Default is Germany</p>
            {cookieSuccessful && <p className={'text-green-700'}>Region successfully updated</p>}
            <Select value={selectedRegion} onChange={(s) => {
                if (s !== null) {
                    console.log('s', s)
                    setSelectedRegion(s)
                }
            }} options={regions}/>
            <Button label={'Set Region'} onClick={() => {
                document.cookie = "solem8s_snipes=" + selectedRegion.value + ";Expires=Tue, 01 Jan 2050 00:00:00 GMT;path=/";
                setCookieSuccessful(true)
                window.open("https://www.snipes." + selectedRegion.value.split('|')[0] + "/login", '_blank');
            }}/>
            <LineSpacer/>
            <h2>Snipes Wishlist Add</h2>
            <form target={'_blank'}
                  method={'POST'}
                  onSubmit={(e) => {
                      setTimeout(function () {
                          window.open('https://www.snipes.' + selectedRegion.value + '/wishlist', '_blank')
                      }, 3000)
                  }}
                  action={'https://www.snipes.' + selectedRegion.value + '/on/demandware.store/' + getSelectedRegionInformation()[0] + '/Wishlist-AddProduct?format=ajax'}>
                <Input name={'pid'}
                       placeholder={'enter pid here...'}
                       label={'Product PID:'}
                       onChange={(e) => {
                           setWishlistPid(e.target.value)
                       }}/>
                <Button label={'Add to wishlist'} onClick={() => console.log('wishlist pid', wishlistPid)}/>
            </form>
            <LineSpacer/>
            <h3>Snipes ATC</h3>
            <form target={'_blank'}
                  method={'POST'}
                  onSubmit={(e) => {
                      setTimeout(function () {
                          window.open('https://www.snipes.' + selectedRegion.value + '/cart', '_blank')
                      }, 3000)
                  }}
                  action={'https://www.snipes.' + selectedRegion.value + '/' + getSelectedRegionInformation()[1]}>
                <Input name={'quantity'} displayLabel={false} type={'hidden'} value={1}/>
                <Input name={'options'} displayLabel={false} type={'hidden'} value={''}/>
                <Input name={'pid'}
                       placeholder={'enter pid here...'}
                       label={'Product PID (Size):'}
                       onChange={(e) => {
                           setAtcPid(e.target.value)
                       }}/>
                <Button label={'Add to cart'} onClick={() => console.log('atc pid', atcPid)}/>
            </form>

        </>
    )
}

const getCookie = (name: string) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);

    if (!parts) {
        return null
    }

    if (parts.length === 2) {
        return parts.pop()!.split(';').shift();
    }

}

export default Snipes
