import { NavLink } from 'react-router-dom'
import React from 'react'

interface INavigationItem {
  name: string
  icon: React.ReactNode
  link: string
  exact?: boolean
}

export const NavigationItem: React.FunctionComponent<INavigationItem> = (props) => {
  const { name, icon, link, exact = false } = props

  return (
    <li>
      <NavLink
        exact={exact}
        className='block text-black text-xs py-4 border-l-4 text-center border-transparent hover:border-red-sb hover:text-red-sb dark:bg-grey-solemate dark:text-white'
        activeClassName={'border-red-sb text-red-sb'}
        to={link}
      >
        <div className='mb-1'>{icon}</div>
        <div>{name}</div>
      </NavLink>
    </li>
  )
}
